import React from "react";

import Header from "./components/header";
import Footer from "./components/footer";

const Yurpomosh = () => {
  return (
    <div>
      <Header />
      <main className="bg-gray-200 py-8 space-y-8">
        <div className="mx-auto max-w-6xl container p-8 bg-white space-y-4">
          <h2>Юридическая помощь населению</h2>
          <div className="news-text">
            <p>
              Наши юристы готовы оказать помощь не только юридическим лицам, но
              и любому человеку, попавшему в сложную ситуацию.
            </p>
            <h3>
              Представление интересов гражданина в государственных органах, в
              том числе и правоохранительных.
            </h3>
            <p>
              Мы часто сталкиваемся с ситуацией, когда «поиск правды» в
              государственных органах требует не только соответствующих
              юридических знаний, но и знаний специфики организации работы
              чиновничьего аппарата, умения поиска соответствующих аргументов,
              документов и доказательств.
            </p>
            <p>
              И уже совсем тяжело контролировать этот процесс, когда речь идет
              об уголовно-правовой защите нарушенных прав и законных интересов.
              Наши юристы, имеющие опыт работы в правоохранительных и других
              государственных органах, возьмут эту задачу на себя.
            </p>
            <p>
              Мы не кудесники, но, если права человека действительно нарушены,
              мы проведем все возможные мероприятия для их восстановления.
            </p>
          </div>
          <h3>Взыскание задолженности</h3>
          <p>
            В последнее время суды загружены делами, связанными с невозвратом
            денежных средств. Получение судебного решения или приказа, как
            правило, не составляет большой сложности. Проблема заключается в
            том, чтобы добиться его исполнения, в том числе и путем, должной
            коммуникации со службой судебных приставов.
          </p>
          <p>
            Метод нашей работы заключается в том, что мы стараемся начать
            работать с досудебной стадии. Грамотный поиск необходимых
            аргументов, позволяет в ряде случаев достичь желаемого результата
            уже на стадии переговоров.
          </p>
          <p>
            Если же медианные процедуры не были результативными, юристы
            агентства начинают работать в рамках судебного процесса, а затем
            сопровождают исполнение принятого решения службой судебных
            приставов.
          </p>
          <h3>Юридическая помощь по гражданским делам</h3>
          <p>
            Сотрудники агентства – это юристы не менее, чем с 10-летним опытом
            работы. В «копилке» «КФП-Консалтинг» ряд успешных для наших клиентов
            дел по наследственным, брачно-семейным, жилищным и ряду других
            категорий дел. При этом если возникает ситуация, когда помимо
            гражданского спора в деле появляются факты нарушения уголовного или
            административного законодательства, мы не рекомендуем искать другого
            юриста, а сами сопровождаем дело в правоохранительных или иных
            государственных органах вплоть до вынесения процессуального решения.
          </p>
          <h3>
            Защита прав и законных интересов граждан в спорах с юридическими
            лицами.
          </h3>
          <p>
            Как правило, обыкновенному человеку сложно защитить свои права в
            спорах с юридическими лицами, будь то государственная или частная
            организация, магазин или медицинская клиника, управляющая или
            страховая компания, крупное промышленное предприятие или небольшая
            мастерская. Причина банальна – у юрлица либо есть штатный юрист,
            либо оно имеет возможность нанять его.
          </p>
          <p>
            И поэтому для того, чтобы добиться положительного результата в
            состязательном процесс человеку тоже необходим профессиональный
            защитник и не только для участия в судебном заседании, но и в
            выработке стратегии защиты, для подготовки юридически грамотных
            документов и ходатая по инстанциям, в компетенцию которых находится
            разрешение данного спора.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Yurpomosh;
